import { css } from '@emotion/react'
import { AppLinkClasses } from '~/components/AppLink'
import { SquareBodyItemClasses } from '~/modules/symbolQuote/square/BodyItem'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'
import styleds from '~/pages/sungop/_private/styleds'

export const sungop_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      .Drawer1 {
        width: 200px;
      }

      .${SquareBodyItemClasses.Container} {
        background-color: #212532;
        border: 0px;
        &:hover {
          background-color: #2e303dcc;
        }
      }
      .${SquareBodyItemClasses.ItemName} {
        background-color: #2c303f;
      }
    }

    ${styleds.PageMenuBox} {
      ${AppLinkClasses.Root} {
        color: white;
        height: 32px;
        font-size: 18px;
        padding-left: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 0;

        :hover {
          border-left: 4px solid rgb(163 163 163);
          background-color: #414141;
        }
      }
    }
  `
}
