import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { op_daytrade_signal } from '~/trades/indicators/sungop/op_daytrade_signal'
import { op_orb } from '~/trades/indicators/sungop/op_orb'
import { op_signal } from '~/trades/indicators/sungop/op_signal'
import { op_trend } from '~/trades/indicators/sungop/op_trend'

export const sungop_strategiesGroup = {
  main: [
    {
      displayName: '當沖',
      symbol: 'TX-1',
      interval: '5',
      indicators: [op_orb, op_trend, op_daytrade_signal],
      panesRatio: 70,
    },
    {
      displayName: '波段',
      symbol: 'TX-1',
      interval: '5',
      indicators: [op_orb, op_trend, op_signal],
      panesRatio: 70,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const sungop_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[op_orb, op_trend, op_daytrade_signal],
  ]

  store.charting.initStrategy({
    configs: [...sungop_strategiesGroup.main],
  })
}
