import { css } from '@emotion/react'
import { memo } from 'react'
import { horizontalScrollbarCss, scrollbar2Css, scrollbarCss } from '~/css/scrollbarCss'
import useMedia from '~/hooks/useMedia'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { useWeightedStocksPoolState } from '~/modules/monitors/useWeightedStocksPoolState'
import { WeightedStockDashboard } from './component/WeightedStockDashboard'
import { WeightedStockRatioList, StockScoreListHeader } from './component/WeightedStockRatioList'

export const Sungop_stockMonitor = memo<ReactProps>(function Sungop_stockMonitor() {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        padding: 16px;
        background-color: #181a1f;
      `}
    >
      <div
        css={css`
          ${fill_vertical_all_center};
          height: auto;
        `}
      >
        <WeightedStockDashboard />
      </div>

      <div
        css={css`
          ${horizontalScrollbarCss};
          height: calc(100% - 180px);
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 32px;
            position: sticky;
            top: 0;
            background-color: #181a1f;
          `}
        >
          <StockScoreListHeader />
        </div>

        <div
          css={css`
            width: 100%;
            height: calc(100% - 32px);
          `}
        >
          <WeightedStockRatioList />
        </div>
      </div>
    </div>
  )
})
