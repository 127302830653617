import { sum } from 'lodash'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { Loading } from '~/components/Loading'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { fill_vertical_all_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { useWeightedStockRatio } from './WeightedStockRatioList'
import { ProgressbarCard, ValueCard } from '../sungop_Sidebar'
import { css } from '@emotion/react'
import IntradayTrendChart from '~/modules/trendChart'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import useMedia from '~/hooks/useMedia'
import { useSymbolStore } from '~/modules/symbolQuote/simple/useSymbolStore'

export const WeightedStockDashboard = memo<ReactProps>(function WeightedStockDashboard() {
  const { isPhone } = useMedia()

  const indexValue = useSnapshot(signalrStore2.values.quote)['TSEA']
  const indexChanges = processQuoteToReadable(indexValue as Signalr.ValueOfOHLC)
  const indexAmplitude = (indexValue?.high ?? 0) - (indexValue?.low ?? 0)

  const data = useWeightedStockRatio()
  const allStockScore = sum(data.map(s => s.contribution))
  const allStockWeight = sum(data.map(s => s.weight))
  const allStockAmp = sum(data.map(s => s.contributionAmp))

  const strongSymbol = data.filter(s => s.change > 0)
  const weakSymbol = data.filter(s => s.change < 0)

  const strongPoint = sum(strongSymbol.map(s => s.contribution))
  const weakPoint = sum(weakSymbol.map(s => s.contribution))

  const amplitudeRatio = (allStockAmp / indexAmplitude) * 100
  const weightRatio = (allStockWeight / 100) * 100

  const currentSymbol = useSnapshot(useSymbolStore).currentSymbol

  return (
    <div
      css={css`
        ${!isPhone ? flex.wrap.crossCenter : flex.wrap.allCenter};
        width: 100%;
        gap: 8px;
        padding: 8px;
      `}
    >
      {!isPhone && (
        <div
          css={css`
            ${fill_vertical_all_center}
            width: 272px;
            height: 140px;
            background-color: #2d303eaa;
            border-radius: 8px;
            padding: 4px;
          `}
        >
          <TitleSymbolQuote.Default symbol={currentSymbol} />
          <IntradayTrendChart
            symbol={currentSymbol}
            ticksSize={12}
            ticksHeight={20}
            priceTicksMargin={-10}
            priceTicksSize={11}
          />
        </div>
      )}
      <ValueCard
        text='大盤漲跌'
        value={indexChanges.closeChange}
      />
      <ValueCard
        text='權值股總分'
        value={allStockScore}
      />
      <ValueCard
        text='多方總分'
        value={strongPoint}
      />
      <ValueCard
        text='空方總分'
        value={weakPoint}
      />
      {/* <ProgressbarCard
        text='影響大盤比重'
        value={amplitudeRatio}
      /> */}
      <ProgressbarCard
        text='權值股總權重'
        value={weightRatio}
      />
    </div>
  )
})
