import { css } from '@emotion/react'
import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { ChipValueDataChart } from './ChipValueDataChart'

export type ChipArray = { title: string; unit: string; date: any; value: any; dataLenght: number }

/** 可以分類的籌碼面板 `加權指數買賣超 外資/自營/投信` */
export const ChipOverViewBoard = memo<ReactProps<{ data: ChipArray[]; bgColor?: string }>>(
  function ChipOverViewBoard(props) {
    return (
      <div
        css={css`
          ${container};
          background-color: ${props.bgColor};
        `}
      >
        <div css={title}>{props.children}</div>
        {props.data.map(s => (
          <ChipValueDataChart
            key={s.title}
            title={s.title}
            unit={s.unit}
            data={s.value}
            date={s.date}
            dataLenght={s.dataLenght}
          />
        ))}
      </div>
    )
  },
)

const container = css`
  ${fill_vertical_cross_center};
  width: 360px;
  height: 320px;
  border-radius: 8px;
  padding: 4px;
  gap: 8px;
  background-color: #222531;
`

const title = css`
  ${fill_horizontal_all_center}
  width: auto;
  height: 26px;
  padding: 0px 60px;
  background-color: #2e303d;
  border-radius: 5px;
`
