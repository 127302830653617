import { memo } from 'react'
import { useMount, useUnmount } from 'react-use'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { css } from '@emotion/react'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'

const QuoteColor = (value: number, previous: number) => {
  if (value >= previous) return '#ff0000'
  else if (value < previous) return '#00ff00'
}

const Arrow = memo<ReactProps<{ symbolNumber: Socket.SymbolString }>>(function Arrow(props) {
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbolNumber])
  const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
  const changeArrow = quoteChanges.closeChange >= 0 ? '▲' : '▼'
  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbolNumber], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbolNumber], 'ohlc')
  })

  return (
    <div
      css={css`
        color: ${QuoteColor(quoteChanges.closeChange, 0)};
      `}
    >
      {changeArrow}
    </div>
  )
})

const Change = memo<ReactProps<{ symbolNumber: Socket.SymbolString }>>(function Change(props) {
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbolNumber])
  const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
  const sign = quoteChanges.closeChange >= 0 ? '+' : ''
  const change = quoteChanges.closeChange
  const close = symbolInfo?.close ?? 0

  const fixedNumber = () => {
    if (close < 50) {
      return 2
    } else if (close > 50 && close < 500) {
      return 1
    } else return NaN
  }
  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbolNumber], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbolNumber], 'ohlc')
  })

  return (
    <div
      css={css`
        color: ${QuoteColor(change, 0)};
      `}
    >
      {sign}
      {change.toFixed(fixedNumber())}
    </div>
  )
})

const ChangePrecentage = memo<ReactProps<{ symbolNumber: Socket.SymbolString }>>(
  function ChangePrecentage(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbolNumber])
    const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
    const sign = quoteChanges.closeChange >= 0 ? '+' : ''
    const changePrecentage = quoteChanges.closeChangePercent
    useMount(() => {
      useSignalrStore.getState().subscribeAdd([props.symbolNumber], 'ohlc')
    })

    useUnmount(() => {
      useSignalrStore.getState().subscribeRemove([props.symbolNumber], 'ohlc')
    })

    return (
      <div
        css={css`
          color: ${QuoteColor(changePrecentage, 0)};
        `}
      >
        {sign}
        {changePrecentage.toFixed(2)}%
      </div>
    )
  },
)

const Close = memo<ReactProps<{ symbolNumber: Socket.SymbolString }>>(function Close(props) {
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbolNumber])
  const close = symbolInfo?.close
  const prevRef = symbolInfo?.prevRef
  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbolNumber], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbolNumber], 'ohlc')
  })

  return (
    <div
      css={css`
        color: ${QuoteColor(close ?? 0, prevRef ?? 0)};
      `}
    >
      {close}
    </div>
  )
})

interface ValueOfOHLCBase {
  ask: number
  bid: number
  close: number
  high: number
  low: number
  open: number
  prevRef: number
  qty: number
  tickQty?: number
  volume: number
  /** 漲停價；若商品無漲跌停限制則會回傳 0 */
  limitUpPrice: number
  /** 跌停價；若商品無漲跌停限制則會回傳 0 */
  limitDownPrice: number
}

const Value = memo<
  ReactProps<{ symbolNumber: Socket.SymbolString; valueType: keyof ValueOfOHLCBase }>
>(function Value(props) {
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbolNumber])
  const value = symbolInfo?.[props.valueType] ?? 0
  const prevRef = symbolInfo?.prevRef
  const volume = value > 1e8 ? Math.round((value / 1e8) * 1) / 1 + '億' : value
  const volumeType = props.valueType === 'volume'
  const closeType = props.valueType === 'close'

  const closeValue = () => {
    if (value < 50) {
      return value.toFixed(2)
    } else if (value > 50 && value < 500) {
      return value.toFixed(1)
    } else return value
  }

  const resultValue = () => {
    if (volumeType) return volume
    else if (closeType) return closeValue()
    else return value
  }

  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbolNumber], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbolNumber], 'ohlc')
  })

  return (
    <div
      css={css`
        color: ${volumeType ? '#ffff00' : QuoteColor(value ?? 0, prevRef ?? 0)};
      `}
    >
      {resultValue()?.toString()}
    </div>
  )
})

export default {
  Arrow,
  Change,
  ChangePrecentage,
  Close,
  Value,
}
